import React, { useEffect, useState } from 'react';
import s from './BannerDinamic.module.css';
import Close from '../icons/Close';
import { set } from 'date-fns';
import Image from 'next/image';

type Elemento = {
  tipo: string;
  data: string;
  _id: string;
};

type BannerItem = {
  _id: string;
  nombre: string;
  elementos: Elemento[];
  estado: boolean;
};

type BannerDinamicProps = {
    // Propiedades
    element: any
    };

const BannerDinamic: React.FC<BannerDinamicProps>  = ({
    // Propiedades
    element
}) => {
    const [banners, setBanners] = useState<BannerItem[]>([]);
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [activeBanner, setActiveBanner] = useState(false);


    // Función para obtener el índice actual desde localStorage
    const getCurrentIndex = () => {
        const savedIndex = localStorage.getItem('currentBannerIndex');
        return savedIndex ? parseInt(savedIndex, 10) : 0;
    };

    // Función para guardar el índice en localStorage
    const saveCurrentIndex = (index: number) => {
        localStorage.setItem('currentBannerIndex', index.toString());
    };

    // Función para aleatorizar el orden de un array
    const shuffleArray = (array: any[]) => {
        return array.sort(() => Math.random() - 0.5);
    };

    console.log('element', element)
  useEffect(() => {
      if (element.status) {
          // Filtrar banners activos
          const activeBanners = element.banner.filter((banner: { estado: boolean, activate: boolean; }) => banner.estado === true && banner.activate === true);
          if (activeBanners.length > 0) {
              setBanners(activeBanners);
              // Obtener el índice actual desde localStorage o inicializarlo
              const index = getCurrentIndex();
              const validIndex = index < activeBanners.length ? index : 0; // Validar que el índice esté dentro de los límites
              setCurrentBannerIndex(validIndex);
              // Establecer el banner activo inicial
              const currentBanner = activeBanners[validIndex];
              setActiveBanner(currentBanner);
          } else {
              console.warn('No hay banners activos');
              setBanners([]);
              setCurrentBannerIndex(0);
              setActiveBanner(false);
          }
      }
  }, [element]);

// Mostrar el siguiente banner en orden al actualizar la página
useEffect(() => {
    if (banners.length > 0) {
        const nextIndex = (currentBannerIndex + 1) % banners.length;
        saveCurrentIndex(nextIndex);
    }
}, [banners, currentBannerIndex]);

    // Función para deserializar datos
    const parseData = (data: string) => {
        try {
        return JSON.parse(data);
        } catch (error) {
        console.error('Error parsing data:', error);
        return null;
        }
    };

    // Función para obtener el estilo de fondo para el banner
    const getBackgroundStyle = (elementos: Elemento[]) => {
        const colorElement = elementos.find(item => item.tipo === 'color');
        if (colorElement) {
        const data = parseData(colorElement.data);
        console.log('data', data)
        if (data) {
          if(data.colors.length === 1){
            return {
              backgroundColor: data.colors[0].color
            };
          }
          return {
            background: `linear-gradient(${data.angle}deg, ${data.colors
                .map((color: any) => `${color.color} ${color.percentage}%`)
                .join(', ')})`,
            };
        }
        }
        // Si no hay un elemento de tipo color, usar fondo negro
        return { backgroundColor: '#FFFFFF' };
    };

    const getColorButtonStyle = (elementos: Elemento[]) => {
        const buttonColorElement = elementos.find(item => item.tipo === 'colorButton');
        if (buttonColorElement) {
          const data = parseData(buttonColorElement.data);
          return data?.color || '#FFFFFF';
        }
        return '#FFFFFF';
      };

        // Verificar si hay banners disponibles
    if (banners.length === 0) {
        return <div>Loading...</div>;
    }

    const handleBannerClose = () => {
        setIsVisible(false);
      };
    
      if (banners.length === 0 || !isVisible) {
        return null;
      }


    const currentBanner = banners[currentBannerIndex];
    const backgroundStyle = getBackgroundStyle(currentBanner.elementos);
    const buttonColor = getColorButtonStyle(currentBanner.elementos);
 
    return (
          activeBanner ? 
          <div
            className={`${s.banner_container}`}
            style={backgroundStyle}
          >    
            <div className={`${s.banner_content}`}>
              {currentBanner.elementos.map((elemento) => {
                const data = parseData(elemento.data);
      
                if (elemento.tipo === 'texto' && data) {
                  return (
                    <div
                      key={elemento._id}
                      className={`${s.banner_text}`}
                      style={{
                        color: data.colorTexto || '#FFFFFF',
                        fontSize: `${data.sizeTexto}px`,
                      }}
                    >
                      {data.textoLink !== '' ?
                      <a href={data.textoLink} target="_blank" rel="noreferrer nofollow">
                        <p>{data.texto}</p>
                      </a>
                      :
                      <p>{data.texto}</p>
                      }
                    </div>
                  );
                }
                if (elemento.tipo === 'imagen' && data) {
                  return (
                    <div key={elemento._id} >
                      {data.imagenLink !== '' ? 
                        <div className={`${currentBanner.nombre == 'minuto a minuto' ? 'w-[10px] h-[10px]' : 'w-[32px] h-[32px]'}`}>
                          <a href={data.imagenLink} target="_blank" rel="noreferrer nofollow">
                              <Image
                                src={data.imagen}
                                alt={data.imagenLink || 'Imagen'}
                                width={32}
                                height={32}
                                layout='responsive'
                              />
                          </a>
                        </div>
                      :
                      <div className={`${currentBanner.nombre == 'minuto a minuto' ? 'w-[10px] h-[10px]' : 'w-[32px] h-[32px]'}`}>
                        <Image
                          src={data.imagen}
                          alt={data.imagenLink || 'Imagen'}
                          width={32}
                          height={32}
                          layout='responsive'
                        />
                      </div>
                      }
                    </div>
                  );
                }
                return null;
              })}
              {/* Botón para cerrar el banner */}
              <button
                  onClick={handleBannerClose}
                  aria-label="Boton cerrar"
                  className={`${s.close_button}`}
                  >
              <Close fill={buttonColor} />
          </button>
            </div>
          </div>
          : null
      );
    };
    

export default BannerDinamic;